import React, {useEffect, useState} from "react";

import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {apiCall} from "../../../api/Api";

import cs from "classnames";
import useInfiniteScroll from "react-infinite-scroll-hook";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";

import {useGlobalContext} from "../../../context/GlobalContext/GlobalContext";

import "./Jobs.scss";
import LongTermJobCard from "../LongTermJobCard/LongTermJobCard";
import UnitSelectBox from "../../../components/shared/SelectBox/UnitSelectBox";
import peakJobsLogo from "../../../assets/svg/peak-jobs-logo.svg";
import peakJobsIcon from "../../../assets/svg/peak-jobs-icon.svg";
import {useResponsiveDimensions} from "../../../lib/hooks/useResponsiveDimensions";
import companyAvatar from "../../../assets/images/company-avatar.png";
import verifiedIcon from "../../../assets/svg/verified-icon.svg";
import ksFlag from "../../../assets/flags/kosova.svg";
import albFlag from "../../../assets/flags/albania.svg";
import {CompanyCountryOption} from "../../PublicApply/PublicApplyForm/PublicApplyForm";
import CountrySelectBox from "../../../components/shared/SelectBox/CountrySelectBox";
import hamburgerIcon from "../../../assets/svg/hamburger-icon.svg";
import {Button, Drawer, Typography} from "@mui/material";
import downloadApp from "../../../assets/images/download-app-modal.png"

const Jobs = () => {
  let today = new Date();
  let tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const params = useParams();
  let { countryCode = "XK", organizationId } = params;

  // If only one param is provided and it’s not a valid country, assume it's the organizationId
  if (!organizationId && countryCode && !["XK", "AL"].includes(countryCode.toUpperCase())) {
    organizationId = countryCode; // Treat it as organizationId
    countryCode = "XK"; // Default country
  }

  const [uppercaseCountryCode, setUppercaseCountryCode] = useState<string>(
    organizationId ? "" : countryCode.toUpperCase()
  );

  const [allAvailableJobs, setAllAvailableJobs] = useState<any | null>([]);
  const [nextPage, setNextPage] = useState<any>("");
  const [isCalled, setIsCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("common");
  const globalCtx = useGlobalContext();
  const [businessUnits, setBusinessUnits] = useState<[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<any>({});
  const [organization, setOrganization] = useState<any>({});
  const [organizationCountry, setOrganizationCountry] = useState<string | null>(null);
  const isMobile = useResponsiveDimensions().isMobile;
  const [menuOpen, setMenuOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true); // Automatically open when user lands

  const countryOptions: CompanyCountryOption[] = [
    {
      name: "Kosovë",
      code: "XK",
      flag: ksFlag,
      phonePrefix: "+383",
      phoneHint: "4xxxxxxx",
    },
    {
      name: "Shqipëri",
      code: "AL",
      flag: albFlag,
      phonePrefix: "+355",
      phoneHint: "6xxxxxxx",
    }
  ];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  const [selectedCountry, setSelectedCountry] = useState<CompanyCountryOption>(countryOptions.find((option) => option.code === uppercaseCountryCode) || countryOptions[0]);

  useEffect(() => {
    callJobs();
  }, [selectedUnit]);

  useEffect(() => {
    getOrganizationCities();
  }, [organizationCountry]);

  useEffect(() => {
    if (!organizationId) {
      // Handle the case where organizationId is not provided
      console.log("No organizationId provided.");
      return;
    }
    const fetchOrganization = async () => {
      if (!organizationId) {
        // Handle the case where organizationId is not provided
        console.log("No organizationId provided.");
        return;
      }
      try {
        const res = await apiCall("get", `b2b/organization/public/${organizationId}/`);
        setOrganization(res.data);
        setOrganizationCountry(res.data.country);
        setSelectedCountry(countryOptions.find((option) => option.code === res.data.country) || countryOptions[0]);
      } catch (err) {
        console.log(err);
      }
    };

    fetchOrganization();
  }, [organizationId]);


  const getOrganizationCities = () => {
    if (!organizationCountry) {
      return;
    }
    console.log("organizationCountry", organizationCountry);
    apiCall("get", `b2c/jobs/cities/?country=${organizationCountry || "XK"}`)
      .then((res: any) => {
        setBusinessUnits(res.data);
      })
      .catch((err) => {
        console.error("Failed to fetch business units:", err);
        // Handle the error, e.g., set an error state or show a message to the user
      });
  }

  const getJobCities = () => {
    if (!organizationId) {
      apiCall("get", "b2c/jobs/cities/?country=" + uppercaseCountryCode)
        .then((res: any) => {
          setBusinessUnits(res.data);
        })
        .catch((err) => {
          console.error("Failed to fetch business units:", err);
          // Handle the error, e.g., set an error state or show a message to the user
        });
    }
  }

  const callJobs = () => {
    setIsLoading(true);
    const url = `b2c/long-term-jobs/guest/?limit=10&city=${selectedUnit?.city_name || ""}&organization=${organizationId || ""}&country=${uppercaseCountryCode}`;

    apiCall("GET", url)
      .then((res) => {
        setAllAvailableJobs(res.data.results);
        // filterDates(res.data.results);
        setIsCalled(true);
        setIsLoading(false);
        setNextPage(res.data?.next);
      })
      .catch((err) => setIsLoading(false));
  };

  const loadMoreJobs = () => {
    if (nextPage !== null) {
      setIsLoading(true);
      let resultUrl = nextPage.split("?")[1];
      apiCall("GET", `b2c/long-term-jobs/guest/?${resultUrl}`)
        .then((res) => {
          setAllAvailableJobs([...allAvailableJobs, ...res.data.results]);
          // filterDates([...allAvailableJobs, ...res.data.results]);
          setIsCalled(true);
          setIsLoading(false);
          setNextPage(res.data?.next);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    callJobs()
    getJobCities();
  }, [uppercaseCountryCode]);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: nextPage,
    onLoadMore: loadMoreJobs,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <div className="Jobs">
      {isMobile ? (
        <div className="MobileHeader">
          <img alt="pic" className="Header__logo" src={peakJobsIcon}/>
          {/*<button className="MobileHeader__menu" onClick={toggleMenu}>*/}
          {/*  ☰*/}
          {/*</button>*/}
          {organization?.name && (
            <div
              className="CompanyDropdown"
            >

              <div
                className={cs(
                  "CompanyDropdown__head"
                )}
              >
                <div className="CompanyDropdown__image-container">
                  <img
                    alt="pic"
                    className="CompanyDropdown__image"
                    src={organization?.logo_url || companyAvatar}
                  />
                  {organization?.is_verified && (
                    <img
                      alt="pic"
                      src={verifiedIcon}
                      className="CompanyDropdown__verified"
                    />
                  )}
                </div>
                <h5
                  className={cs(
                    "CompanyDropdown__name",
                  )}
                >
                  {organization?.name || "Company Name"}
                </h5>
              </div>
            </div>
          )}
          <img
            alt="pic"
            src={hamburgerIcon}
            className="MobileHeader__menu"
            onClick={() => {
              toggleMenu();
            }}
          />
          {menuOpen && (
            <div className="MobileHeader__menu-content">
                <UnitSelectBox
                  items={businessUnits}
                  selectedItem={selectedUnit}
                  onItemSelect={(item: any) => {
                    setSelectedUnit(item); // Set selected unit only
                    toggleMenu()
                  }}
                  placeholder={t("city")}
                  onClick={() => {
                  }}
                  onBlur={(e: any) => {
                  }}
                  onChange={(e: any) => {
                  }}
                  onAllUnitsClicked={() => {
                    setSelectedUnit({}); // Clear selected unit for "All" option
                  }}
                />
                {!organizationId && (
                  <CountrySelectBox
                    items={countryOptions}
                    selectedItem={selectedCountry}
                    onItemSelect={(item: any) => {
                      setSelectedCountry(item); // Set selected unit only
                      setUppercaseCountryCode(item.code);
                      toggleMenu()
                    }}
                    placeholder={t("country")}
                    onClick={() => {
                    }}
                    onBlur={(e: any) => {
                    }}
                    onChange={(e: any) => {
                    }}
                    onAllUnitsClicked={() => {
                      setSelectedUnit({}); // Clear selected unit for "All" option
                    }}
                  />
                )}
            </div>
          )}
        </div>
      ) : (
        <div className="Jobs__header">
          <div className="Jobs__header">
            <div className="Header__left">
              {isMobile ? (
                <img alt="pic" className="Header__logo" src={peakJobsIcon}/>
              ) : (
                <img alt="pic" className="Header__logo" src={peakJobsLogo}/>
              )}
            </div>
            <div className="Jobs__header__right">

              {organization?.name && (
                <div
                  className="CompanyDropdown"
                >

                  <div
                    className={cs(
                      "CompanyDropdown__head"
                    )}
                  >
                    <div className="CompanyDropdown__image-container">
                      <img
                        alt="pic"
                        className="CompanyDropdown__image"
                        src={organization?.logo_url || companyAvatar}
                      />
                      {organization?.is_verified && (
                        <img
                          alt="pic"
                          src={verifiedIcon}
                          className="CompanyDropdown__verified"
                        />
                      )}
                    </div>
                    <h5
                      className={cs(
                        "CompanyDropdown__name",
                      )}
                    >
                      {organization?.name || "Company Name"}
                    </h5>
                  </div>
                </div>
              )}
              <UnitSelectBox
                items={businessUnits}
                selectedItem={selectedUnit}
                onItemSelect={(item: any) => {
                  setSelectedUnit(item); // Set selected unit only
                }}
                placeholder={t("city")}
                onClick={() => {
                }}
                onBlur={(e: any) => {
                }}
                onChange={(e: any) => {
                }}
                onAllUnitsClicked={() => {
                  setSelectedUnit({}); // Clear selected unit for "All" option
                }}
              />
              {!organizationId && (
                <CountrySelectBox
                  items={countryOptions}
                  selectedItem={selectedCountry}
                  onItemSelect={(item: any) => {
                    setSelectedCountry(item); // Set selected unit only
                    setUppercaseCountryCode(item.code);
                  }}
                  placeholder={t("country")}
                  onClick={() => {
                  }}
                  onBlur={(e: any) => {
                  }}
                  onChange={(e: any) => {
                  }}
                  onAllUnitsClicked={() => {
                    setSelectedUnit({}); // Clear selected unit for "All" option
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="Jobs__header-spacer"/>
      {allAvailableJobs?.length === 0 && isCalled && !loading && (
        <div className="Jobs__no-content">
          {t("noAvailableJobs")}
        </div>
      )}
      {allAvailableJobs?.length > 0 && (
        <div className="Jobs__timeline">
          <div className="Jobs__timeline-container">
            <div className="Jobs__timeline-content">
              {allAvailableJobs?.map((job: any, index: number) => (
                <div key={index} className="Jobs__job-wrapper">
                  <LongTermJobCard job={job} shiftTotal={true}/>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="Jobs__infinite-scroll-wrapper">
        {nextPage && true && (
          <div ref={sentryRef}>
            <LoadingComponent/>
          </div>
        )}
      </div>

      {isLoading && allAvailableJobs.length === 0 && (
        <div style={{margin: "150px auto"}}>
          <LoadingComponent/>
        </div>
      )}
      {isMobile && (
        <Drawer
          anchor="bottom"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <div style={{ padding: "16px", textAlign: "center" }}>
            <img
              alt="pic"
              className="Jobs__modal-image"
              src={downloadApp}
            />
            <div style={{ textAlign: "left", padding: "16px" }}>

              <Typography variant="caption" display="block">
                • Ndjekja e aplikimeve – <span className="Jobs__modal-hint">Informohu kur punëdhënësit shikojnë profilin tënd.</span>
              </Typography>

              <Typography variant="caption" display="block">
                • Oferta pune të personalizuara – <span className="Jobs__modal-hint">Merr oferta pa pasur nevojë të aplikosh.</span>
              </Typography>

              <Typography variant="caption" display="block">
                • Njoftime për punë – <span className="Jobs__modal-hint">Mos humb mundësi të reja punësimi.</span>
              </Typography>
            </div>


            <div style={{ marginTop: "12px", display: "flex", justifyContent: "center", gap: "10px" }}>
              <Button variant="contained" color="primary" href={"https://onelink.to/4kkqq4"} target="_blank">
                {t("downloadApp")}
              </Button>
            </div>
            <Button
              onClick={() => setDrawerOpen(false)}
              style={{ marginTop: "12px", display: "block", width: "100%", color: "#605f5f" }}
            >
              {t("maybeLater")}
            </Button>
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default Jobs;
