import React, {useRef, useState} from "react";
import {useOutsideClickDetection} from "../../../lib/hooks/useOutSideClickDetection";
import cs from "classnames";
import chevronDown from "../../../assets/svg/chevron-down.svg";

import "./CountrySelectBox.scss";
import plusIcon from "../../../assets/svg/plus-icon.svg";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ClearAll} from "@mui/icons-material";
import {CompanyCountryOption} from "../../../pages/PublicApply/PublicApplyForm/PublicApplyForm";

export interface ItemsType {
  name: string | undefined | any;
  city_name?: string | undefined;
  title?: any;
  value?: string | undefined;
}

interface CountrySelectBoxProps {
  items?: CompanyCountryOption[];
  className?: string;
  className2?: string;
  style?: any;
  selectedItem: any;
  onItemSelect: (name: any) => void;
  onClick: () => void;
  labelStyle?: React.CSSProperties;
  placeholder?: string;
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
  onAllUnitsClicked: () => void;
}

const CountrySelectBox = (props: CountrySelectBoxProps) => {
  const {
    items, selectedItem, className, className2, style, labelStyle, placeholder, onBlur,
    onChange, onAllUnitsClicked
  } =
    props;
  const {t} = useTranslation("common");
  const [showItems, setShowItems] = useState(false);

  const dropDownRef = useRef(null);

  const navigate = useNavigate();

  const onItemSelect = (name: any) => {

    props.onItemSelect(name);
    setShowItems(!showItems);
  };

  const handleOutSideClick = () => {
    setShowItems(false);
  };

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  return (
    <div ref={dropDownRef} className={cs("CountrySelectBox", className)} style={style}>
      <div className="CountrySelectBox__container" onClick={() => setShowItems(!showItems)}>
        <span
          className="CountrySelectBox__text"
          style={labelStyle}
        >
          <img
            src={selectedItem.flag}
            alt={selectedItem.name}
            style={{width: 20, height: 15, marginRight: 10}}
          /> {selectedItem?.name || placeholder}
        </span>

        <img
          className="CountrySelectBox__icon"
          src={chevronDown}
          alt="chevron down icon"
        />
      </div>
      <div
        className={cs("CountrySelectBox__items", showItems && "active", className2)} >
        {items?.map((item, index) => {
          return (
            <div
              onClick={() => {
                onItemSelect(item);
              }}
              className={`CountrySelectBox__item-container ${
                props.selectedItem.name === item.name ? "activeLabel" : ""
              }`}
              key={index}
            >
              {item.name && (<p className="CountrySelectBox__item">
                <img
                  src={item.flag}
                  alt={item.name}
                  style={{width: 20, height: 15, marginRight: 10}}
                />
                {item.name}
              </p>)}
            </div>
          );
        })}

      </div>
    </div>
  );
};

export default CountrySelectBox;