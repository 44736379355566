import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import {usePublicApplyFormik} from "./usePublicApplyFormik";
import {apiCall, apiCallWithoutAuth} from "../../../api/Api";
import {useNavigate} from "react-router-dom";
import {useGlobalContext} from "../../../context/GlobalContext/GlobalContext";
import {useAuthContext} from "../../../context/AuthContext/AuthContext";
import {convertRestApiErrorCodes} from "../../../lib/helpers/handleErrors";
import FileUploader from "../../../components/shared/FileUploader/FileUploader";
import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";
import penLogo from "../../../assets/svg/pen-logo.svg";
import deleteIcon from "../../../assets/svg/delete-icon.svg";
import profileAvatar from "../../../assets/images/user-avatar.png";
import peakJobsIcon from "../../../assets/svg/peak-jobs-icon.svg";
import {auth} from "../../../firebaseConfig";

import "./PublicApplyForm.scss";
import ksFlag from "../../../assets/flags/kosova.svg";
import albFlag from "../../../assets/flags/albania.svg";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {MenuItem, Radio, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from '@mui/material/RadioGroup';
import {Delete, School, Work} from "@mui/icons-material";
import MUIButton from '@mui/material/Button';
import AddEducationModal from "../../../components/shared/AddEducationModal/AddEducationModal";
import dayjs from "dayjs";
import AddExperienceModal from "../../../components/shared/AddExperienceModal/AddExperienceModal";
import WarningModal from "../../../components/shared/WarningModal/WarningModal";
import LoginModal from "../../../components/shared/LoginModal/LoginModal";
import "dayjs/locale/sq";


export interface CompanyCountryOption {
  name: string;
  code: string;
  flag: string;
  phonePrefix: string;
  phoneHint: string;
}


const cities = [
  "Deçan",
  "Gjakovë",
  "Gllogoc",
  "Gjilan",
  "Dragash",
  "Istog",
  "Kaçanik",
  "Klinë",
  "Fushë Kosovë",
  "Kamenicë",
  "Mitrovicë",
  "Leposaviq",
  "Lipjan",
  "Novobërdë",
  "Obiliq",
  "Rahovec",
  "Pejë",
  "Podujevë",
  "Prishtina",
  "Prizren",
  "Skenderaj",
  "Shtime",
  "Shtërpcë",
  "Suharekë",
  "Ferizaj",
  "Viti",
  "Vushtrri",
  "Zubin Potok",
  "Zveçan",
  "Malishevë",
  "Hani i Elezit",
  "Mamushë",
  "Junik",
  "Graçanicë",
  "Ranillug",
  "Partesh",
  "Kllokot"
];

const albanianCities = [
  "Berat",
  "Bulqizë",
  "Delvinë",
  "Devoll",
  "Dibër",
  "Divjakë",
  "Durrës",
  "Dropull",
  "Elbasan",
  "Fier",
  "Finiq",
  "Gjirokastër",
  "Gramsh",
  "Has",
  "Himarë",
  "Kavajë",
  "Kolonjë",
  "Korçë",
  "Krujë",
  "Kuçovë",
  "Kukës",
  "Kurbin",
  "Lezhë",
  "Libohovë",
  "Librazhd",
  "Lushnjë",
  "Malësi e Madhe",
  "Mallakastër",
  "Mat",
  "Memaliaj",
  "Mirditë",
  "Patos",
  "Peqin",
  "Përmet",
  "Pogradec",
  "Poliçan",
  "Prrenjas",
  "Pukë",
  "Roskovec",
  "Sarandë",
  "Selenicë",
  "Shijak",
  "Shkodër",
  "Skrapar",
  "Tepelenë",
  "Tiranë",
  "Tropojë",
  "Ura Vajgurore (Dimal)",
  "Vau i Dejës",
  "Vlorë",
  "Vorë",
  "Rrogozhinë",
  "Klos",
  "Konispol",
  "Fushë-Arrëz",
  "Cërrik",
  "Maliq",
  "Ersekë"
];

interface PublicApplyFormProps {
  publicJobId: any;
  referrer: string;
}


const PublicApplyForm = (props: PublicApplyFormProps) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isImageUploaderOpen, setIsImageUploaderOpen] = useState(false);
  const [base64Image, setBase64Image] = useState("");
  const [base64ImageError, setBase64ImageError] = useState(false);
  const {t} = useTranslation("common");
  const navigate = useNavigate();
  const globalCtx = useGlobalContext();
  const authCtx = useAuthContext();
  const [openAddEducationModal, setOpenAddEducationModal] = useState(false);
  const [educations, setEducations] = useState<any[]>([]);
  const [openAddExperienceModal, setOpenAddExperienceModal] = useState(false);
  const [experiences, setExperiences] = useState<any[]>([]);
  const [showAccountExistsModal, setShowAccountExistsModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [showAppliedStatusModal, setShowAppliedStatusModal] = useState(false);
  const [isCompletingProfile, setIsCompletingProfile] = useState(false);
  const [showAlreadyAppliedModal, setShowAlreadyAppliedModal] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState<"applied" | "already_applied" | "error" | null>(null);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [showDirectApplyModal, setShowDirectApplyModal] = useState(false);

  const countryOptions: CompanyCountryOption[] = [
    {
      name: "Kosova",
      code: "XK",
      flag: ksFlag,
      phonePrefix: "+383",
      phoneHint: "4xxxxxxx",
    },
    {
      name: "Albania",
      code: "AL",
      flag: albFlag,
      phonePrefix: "+355",
      phoneHint: "6xxxxxxx",
    }
  ];


  const addEducation = (education: any) => {
    setEducations([...educations, education]);
  }

  const addExperience = (experience: any) => {
    setExperiences([...experiences, experience]);
  }

  useEffect(() => {
    if (applicationStatus) {
      console.log("Application status:", applicationStatus);
      setShowAppliedStatusModal(true);
    }
  }, [applicationStatus]);

  const applyWithAccount = async () => {
    try {
      setIsButtonLoading(true);
      const application = await apiCall("POST", `b2c/applications/public-apply/${props.publicJobId}/`);
      console.log("Application:", application);
      setApplicationStatus("applied");
    } catch (error: any) {
      if (error?.response?.data?.code === "already_applied") {
        console.log("Already applied");
        setApplicationStatus("already_applied");
      } else {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage || t("somethingWentWrong"));
        setApplicationStatus("error");
      }
    }
  }

  const formik = usePublicApplyFormik({
    defaultCountry: countryOptions[0],
    onSubmit: async (values: any) => {
      console.log("Form submitted:", values);
      if (base64Image.length === 0) {
        setBase64ImageError(true);
        return;
      }
      try {
        setIsButtonLoading(true);
        const profileDataObject = {
          display_name: values.nameSurname,
          language: globalCtx.selectedLanguage.keyword,
          phone_number: `${values.phonePrefix}${values.phoneNumber}`,
        };

        const profile = {
          first_name: values.firstName,
          last_name: values.lastName,
          has_driving_license: values.haveDrivingLicense,
          gender: values.gender,
          address: values.address,
          picture_data: base64Image,
          nationality: 'XK',
          date_of_birth: values.dateOfBirth,
        }
        const user = {
          email: values.email,
          phone_number: `${values.phonePrefix}${values.phoneNumber}`,
        }

        const data = {
          user: user,
          profile: profile,
          education: educations,
          experience: experiences,
          source: props.referrer,
        };

        if (isCompletingProfile) {
          const applicationData = await apiCall("POST", `public/jobs/registered-apply/${props.publicJobId}/`, data)
          console.log("Application data:", applicationData);
          setApplicationStatus("applied");
        } else {

          // apply
          const applicationData = await apiCallWithoutAuth("POST", `public/jobs/apply/${props.publicJobId}/`, data)
          console.log("Application data:", applicationData);
          setApplicationStatus("applied");
        }

      } catch (error: any) {
        if (error?.response?.data?.code === "already_applied") {
          setApplicationStatus("already_applied");
        }
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
      } finally {
        setIsButtonLoading(false);
      }
    },
  });

  const checkIfPhoneNumberExists = async (phoneNumber: string) => {
    try {
      const response = await apiCall("GET", `public/jobs/check-phone-number/${formik.values.phonePrefix}${phoneNumber}/`);
      console.log("Response:", response);
      if (!response.data.is_free) {
        setShowAccountExistsModal(true);
      }
    } catch (error: any) {
      const errorMessage = convertRestApiErrorCodes(
        error?.response?.data?.code,
        t,
      );
      globalCtx.openMessageModal("error", errorMessage || t("somethingWentWrong"));
    }
  }

  useEffect(() => {
    //   check if user is already logged in
    if (auth.currentUser) {
      // get user profile
       apiCall("GET", "b2c/user/").then((response) => {
        if (response.data.profile) {
          setIsCompletingProfile(false);
          setIsEditingProfile(true);
          setShowDirectApplyModal(true);
          formik.setFieldValue("email", auth.currentUser?.email || "");
          formik.setFieldValue("firstName", response.data.profile.first_name);
          formik.setFieldValue("lastName", response.data.profile.last_name);
          formik.setFieldValue("gender", response.data.profile.gender);
          formik.setFieldValue("address", response.data.profile.address);
          formik.setFieldValue("dateOfBirth", dayjs(response.data.profile.date_of_birth).format('YYYY-MM-DD'));
          formik.setFieldValue("haveDrivingLicense", response.data.profile.has_driving_license);
          formik.setFieldValue("phoneNumber", response.data.user.phone_number.slice(4));
          setBase64Image(response.data.profile.picture_data);
        } else {
          console.log("User has no profile");
          setIsCompletingProfile(true);
        }
      });

       apiCall("GET", "b2c/education/").then((response) => {
         if (response.data) {
           setEducations(response.data.results);
         } else {
           console.log("User has no education");
         }
       });

        apiCall("GET", "b2c/experience/").then((response) => {
          if (response.data) {
            setExperiences(response.data.results);
          } else {
            console.log("User has no experience");
          }
        });

    }

  }, []);


  useEffect(() => {
    if (isCompletingProfile) {
      formik.setFieldValue("email", auth.currentUser?.email || "");
    }
  }, [isCompletingProfile]);

  useEffect(() => {
    if (auth.currentUser === null && !isCompletingProfile && !isEditingProfile) {
      if (formik.values.phoneNumber.length === 8 || formik.values.phoneNumber.length === 9) {
        checkIfPhoneNumberExists(formik.values.phoneNumber).then(r => r);
      }
    }
  }, [formik.values.phoneNumber]);

  const clearPhoneNumber = () => {
    formik.setFieldValue("phoneNumber", "");
  }

  return (
    <>
      {isImageUploaderOpen && (
        <ModalContainer>
          <FileUploader
            maxFileSizeInBytes={5242880}
            accept="image/*"
            onCancel={() => {
              setIsImageUploaderOpen(false);
            }}
            onSubmit={(fileName, base64) => {
              if (base64 !== undefined) {
                setBase64Image(base64);
                setIsImageUploaderOpen(false);
                setBase64ImageError(false);
              }
            }}
          />
        </ModalContainer>
      )}
      {openAddEducationModal && (
        <AddEducationModal
          title={t("fillTheFormToAddEducation")}
          buttonLabel={t("addEducation")}
          onCancel={() => setOpenAddEducationModal(false)}
          onSubmit={(education: any) => {
            addEducation(education);
            setOpenAddEducationModal(false);
          }}
        />
      )}

      {openAddExperienceModal && (
        <AddExperienceModal
          title={t("fillTheFormToAddExperience")}
          buttonLabel={t("addExperience")}
          onCancel={() => setOpenAddExperienceModal(false)}
          onSubmit={(experience: any) => {
            addExperience(experience);
            setOpenAddExperienceModal(false);
          }}
        />
      )}

      {showDirectApplyModal && (
        <WarningModal
          onCancel={() => {
            setShowDirectApplyModal(false);
            setIsCompletingProfile(false);
            setIsEditingProfile(false);
            auth.signOut();
            // clear form
            formik.resetForm();
            setBase64Image("");
            setEducations([]);
            setExperiences([]);
          }}
          onSubmit={() => {
            setShowDirectApplyModal(false);
            applyWithAccount();
          }}
          buttonLabel={t("apply")}
          title={t("youAreLoggedInWithEmail") + ": " + formik.values.email + ". " + t("areYouSureYouWantToApplyWithThisAccount")}
          hideCancelBtn={false}
        />
      )}



      {showAccountExistsModal && (
        <WarningModal
          onCancel={() => {
            setShowAccountExistsModal(false);
            clearPhoneNumber()
          }}
          onSubmit={() => {
            setShowAccountExistsModal(false);
            setShowLoginModal(true);
          }}
          buttonLabel={t("login")}
          title={t("accountExists") + " " + t("doYouWantToLogin")}
          hideCancelBtn={false}
        />
      )}

      {showAppliedStatusModal && (
        <WarningModal
          onCancel={() => {
            setShowAppliedStatusModal(false);
            // navigate("/");
            // navigate to www.peakjobs.app
            window.location.href = "https://peakjobs.app";

          }}
          onSubmit={() => {
            setShowAppliedStatusModal(false);
            // navigate("/");
            window.location.href = "https://peakjobs.app";

          }}
          buttonLabel={t("okay")}
          title={
            applicationStatus === "applied"
              ? t("applicationSubmittedSuccessfully")
              : applicationStatus === "already_applied"
                ? t("alreadyApplied")
                : t("somethingWentWrong")
            // t("applicationSubmittedSuccessfully")
          }
          hideCancelBtn={true}
          status={applicationStatus === "applied" ? "success" : applicationStatus === "already_applied" ? "warning" : "error"}
        />
      )}

      {showVerifyEmailModal && (
        <WarningModal
          onCancel={() => {
            setShowVerifyEmailModal(false);
            clearPhoneNumber();
          }}
          onSubmit={() => {
            setShowVerifyEmailModal(false);
            setShowLoginModal(true);
          }}
          buttonLabel={t("okay")}
          title={t("verifyYourEmailToApply")}
          hideCancelBtn={true}
        />
      )}

      {showLoginModal && (
        <LoginModal
          onCancel={() => {
            setShowAccountExistsModal(false);
            setShowLoginModal(false);
            clearPhoneNumber();
          }}
          onSubmit={() => {
            applyWithAccount();
            setShowLoginModal(false);
          }}
          buttonLabel={t("login")}
          title={t("accountExists") + " " + t("doYouWantToLogin")}
          hideCancelBtn={false}
          onEmailIsNotVerified={() => {
            setShowLoginModal(false);
            setShowVerifyEmailModal(true);
            // clearPhoneNumber();
          }}
          onUserHasNoProfile={() => {
            setShowLoginModal(false);
            setIsCompletingProfile(true);
          }}
        />
      )}
      <div className="PublicApplyForm">
        {!isEditingProfile && !auth.currentUser && (
          !isCompletingProfile ? (
          <div className="PublicApplyForm__info-tip">
            <p className="PublicApplyForm__info-tip--text">{t("fillFormToApply")}</p>
            <p className="PublicApplyForm__info-tip--text">{t("or")}</p>

            <Button
              type="submit"
              style={{height: "60px", marginLeft: "5px"}}
              label={t("applyWithPeakjobs")}
              onClick={() => {
                setShowLoginModal(true);
              }}
              className="PublicApplyForm__login-button"
              imgSrc={peakJobsIcon}
            />
          </div>
        ) : (
          <div className="PublicApplyForm__info-tip">
            <img src={peakJobsIcon} alt="peakjobs logo" className="PublicApplyForm__info-tip--icon"/>
            <p className="PublicApplyForm__info-tip--text">{t("completeYourProfileToApply")}</p>
          </div>
          )
        )}
        <div className="PublicApplyForm__body">
          <div className="CompanyDetails">
            <div className="PublicApplyDetails__body">
              <div className="PublicApplyDetails__input">
                <div className="PublicApplyDetails__input-row">
                  <div className="PublicApplyDetails__uploader">
                    <div className="PublicApplyDetails__circle">
                      {base64Image === "" ? (
                        <img
                          alt="pic"
                          src={profileAvatar}
                          className="PublicApplyDetails__placeholder"
                        />
                      ) : (
                        <img
                          alt="pic"
                          src={base64Image}
                          className="PublicApplyDetails__image"
                        />
                      )}

                      <img
                        alt="pic"
                        src={base64Image === "" ? penLogo : deleteIcon}
                        className="PublicApplyDetails__pen"
                        onClick={() => {
                          base64Image === ""
                            ? setIsImageUploaderOpen(true)
                            : setBase64Image("");
                        }}
                      />
                    </div>

                  </div>

                  <InputField
                    type="text"
                    placeholder={t("firstName")}
                    name="firstName"
                    value={formik.values.firstName}
                    onBlur={(e: any) => formik.handleBlur(e)}
                    onChange={(e: any) => formik.handleChange(e)}
                  />

                  <InputField
                    type="text"
                    placeholder={t("lastName")}
                    name="lastName"
                    value={formik.values.lastName}
                    onBlur={(e: any) => formik.handleBlur(e)}
                    onChange={(e: any) => formik.handleChange(e)}
                  />
                </div>
                <div className="PublicApplyDetails__input-description">
                  {t("addProfileAndFirstLastName")}
                </div>
                {formik.errors.firstName && formik.touched.firstName && (
                  <h5 className="PublicApplyDetails__input-error">
                    {formik.errors.firstName as string}
                  </h5>
                )}
                {formik.errors.lastName && formik.touched.lastName && (
                  <h5 className="PublicApplyDetails__input-error">
                    {formik.errors.lastName as string}
                  </h5>
                )}
                {base64Image === '' && formik.touched.firstName && (
                  <h5 className="PublicApplyDetails__input-error">
                    {t("addProfilePicture")}
                  </h5>
                )}
              </div>
              {" "}
              <div className="PublicApplyDetails__input">
                <div className="PublicApplyDetails__input-row">
                  {/* Country Dropdown for Phone Prefix */}
                  <FormControl fullWidth className="InputField__container">
                    <Select
                      id="phone-prefix-select"
                      name="phonePrefix"
                      value={formik.values.phonePrefix || ""}
                      onChange={(event) => {
                        const selectedPrefix = event.target.value;
                        const selectedCountry = countryOptions.find(
                          (option) => option.phonePrefix === selectedPrefix
                        );
                        formik.setFieldValue("companyCountry", selectedCountry || null); // Update country
                        formik.setFieldValue("phonePrefix", selectedCountry?.phonePrefix || ""); // Update prefix
                      }}
                      variant="standard"
                      fullWidth
                      disableUnderline
                      displayEmpty
                      sx={{
                        fontFamily: '"Manrope-Bold", sans-serif',
                        backgroundColor: "white",
                        border: "none",
                        boxShadow: "none",
                        padding: "0px 0px 0px 16px",
                        "&:hover": {
                          backgroundColor: "white",
                        },
                        "&:focus": {
                          backgroundColor: "white",
                          outline: "none",
                        },
                        "& .MuiSelect-icon": {
                          marginRight: "10px",
                        },
                        "& .MuiSelect-select": {
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        },
                      }}
                    >
                      {countryOptions.map((option) => (
                        <MenuItem key={option.code} value={option.phonePrefix}>
                          <div style={{display: "flex", alignItems: "center"}}>
                            <img
                              src={option.flag}
                              alt={option.name}
                              style={{width: 20, height: 15, marginRight: 10}}
                            />
                            {option.phonePrefix}
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Phone Number Input */}
                  <InputField
                    type="text"
                    placeholder={
                      formik.values.phonePrefix
                        ? countryOptions.find(
                        (option) => option.phonePrefix === formik.values.phonePrefix
                      )?.phoneHint || "" // Use the phoneHint of the selected country
                        : ""
                    }
                    name="phoneNumber"
                    value={formik.values.phoneNumber}
                    onBlur={(e: any) => formik.handleBlur(e)}
                    onChange={(e: any) => formik.handleChange(e)}
                  />
                </div>
                <div className="PublicApplyDetails__input-description">
                  {t(
                    "verifyPhoneNumber.modal.sendMessage.title",
                  )}
                </div>

              </div>
              {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                <h5 className="PublicApplyDetails__input-error">
                  {formik.errors.phoneNumber as string}
                </h5>
              )}
              {" "}

              <div className="PublicApplyDetails__textArea">
                <InputField
                  type="text"
                  placeholder={t("email")}
                  name="email"
                  value={formik.values.email}
                  onBlur={(e: any) => formik.handleBlur(e)}
                  onChange={(e: any) => formik.handleChange(e)}
                  disabled={isCompletingProfile}
                />
                <div className="PublicApplyDetails__input-description">
                  {t(
                    "addYourEmail",
                  )}
                </div>
                {formik.errors.email &&
                  formik.touched.email && (
                    <h5 className="PublicApplyDetails__textArea-error">
                      {formik.errors.email as string}
                    </h5>
                  )}
              </div>

              {" "}
              <div className="PublicApplyDetails__input">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sq-SQ">
                  <DatePicker
                    value={formik.values.dateOfBirth ? dayjs(formik.values.dateOfBirth) : null}
                    className="InputField__container"
                    onChange={(newValue) => formik.setFieldValue('dateOfBirth', dayjs(newValue).format('YYYY-MM-DD'))}
                    slotProps={{textField: {placeholder: t("birthDate")}}}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        width: '100%',
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputBase-input': {
                        padding: '0 20px',
                        height: '71px',
                        fontSize: '16px',
                        fontFamily: '"Manrope-Bold", sans-serif',
                        textAlign: 'left'
                      },
                    }}
                  />
                </LocalizationProvider>

                <div className="PublicApplyDetails__input-description">
                  {t(
                    "birthDateMoreThan18",
                  )}
                </div>
                {formik.errors.dateOfBirth &&
                  formik.touched.dateOfBirth && (
                    <h5 className="PublicApplyDetails__input-error">
                      {formik.errors.dateOfBirth as string}
                    </h5>
                  )}
              </div>
              {" "}

              <div className="PublicApplyDetails__input">
                <div className="PublicApplyDetails__input-row">
                  <FormControl fullWidth className="InputField__container">
                    <Select
                      id="gender-select"
                      value={formik.values.gender || ""}
                      onChange={(event) =>
                        formik.setFieldValue("gender", event.target.value)
                      }
                      variant="standard"
                      fullWidth
                      disableUnderline
                      displayEmpty
                      sx={{
                        fontFamily: '"Manrope-Bold", sans-serif',
                        backgroundColor: 'white',
                        border: 'none',
                        boxShadow: 'none',
                        padding: '0px 0px 0px 16px',
                        '&:hover': {
                          backgroundColor: 'white',
                        },
                        '&:focus': {
                          backgroundColor: 'white',
                          outline: 'none',
                        },
                        '& .MuiSelect-icon': {
                          marginRight: '10px',
                        },
                        '& .MuiSelect-select': {
                          backgroundColor: 'white',
                          color: formik.values.gender ? 'black' : '#b2b2b2',
                        },
                      }}
                    >
                      {/* Hint item */}
                      <MenuItem
                        value=""
                        disabled
                        sx={{
                          color: 'gray',
                        }}
                      >
                        {t("gender")}
                      </MenuItem>
                      {/* Gender items */}
                      <MenuItem value="M">{t("male")}</MenuItem>
                      <MenuItem value="F">{t("female")}</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth className="InputField__container">
                    <Select
                      id="city-select"
                      value={formik.values.address || ""}
                      onChange={(event) => formik.setFieldValue("address", event.target.value)}
                      variant="standard"
                      fullWidth
                      disableUnderline
                      displayEmpty
                      sx={{
                        fontFamily: '"Manrope-Bold", sans-serif',
                        backgroundColor: 'white',
                        border: 'none',
                        boxShadow: 'none',
                        padding: '0px 0px 0px 16px',
                        '&:hover': {
                          backgroundColor: 'white',
                        },
                        '&:focus': {
                          backgroundColor: 'white',
                          outline: 'none',
                        },
                        '& .MuiSelect-icon': {
                          marginRight: '10px',
                        },
                        '& .MuiSelect-select': {
                          backgroundColor: 'white',
                          color: formik.values.gender ? 'black' : '#b2b2b2',
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        {t("vendbanimi")}
                      </MenuItem>
                      {formik.values.companyCountry?.code === "XK" ? (
                        cities.map((city) => (
                          <MenuItem key={city} value={city}>
                            {city}
                          </MenuItem>
                        ))
                      ) : (
                        albanianCities.map((city) => (
                          <MenuItem key={city} value={city}>
                            {city}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>


                </div>
                <div className="PublicApplyDetails__input-description">
                  {t(
                    "selectGenderAndAddress",
                  )}
                </div>
                {formik.errors.gender && formik.touched.gender && (
                  <h5 className="PublicApplyDetails__input-error">
                    {formik.errors.gender as string}
                  </h5>
                )}
                {formik.errors.address && formik.touched.address && (
                  <h5 className="PublicApplyDetails__input-error">
                    {formik.errors.address as string}
                  </h5>
                )}
              </div>
              {" "}

              <FormControl>
                <p className="PublicApplyForm__question">
                  {t("doYouHaveDrivingLicense")}
                </p>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formik.values.haveDrivingLicense ? "yes" : "no"}
                  onChange={(event) => {
                    formik.setFieldValue("haveDrivingLicense", event.target.value === "yes");
                  }}
                >
                  <FormControlLabel value="yes" control={<Radio/>} label={t("yes")}/>
                  <FormControlLabel value="no" control={<Radio/>} label={t("no")}/>
                </RadioGroup>
              </FormControl>
              {formik.errors.haveDrivingLicense && formik.touched.haveDrivingLicense && (
                <h5 className="PublicApplyDetails__input-error">
                  {formik.errors.haveDrivingLicense as string}
                </h5>
              )}
              <div className="PublicApplyDetails__input-button-text-row">
                <p className="PublicApplyForm__button-front-text">
                  {t("education")}
                </p>
                <MUIButton
                  variant="outlined"
                  startIcon={<School/>}
                  color="inherit"
                  onClick={() => {
                    setOpenAddEducationModal(true);
                  }}
                >
                  {t("addEducation")}
                </MUIButton>
              </div>
              {educations && (
                <div className="PublicApplyForm__personal-details-item">

                  <p className="PublicApplyForm__personal-details-value">
                    {educations && educations.length > 0 ? (
                      educations.map((item: any, index: number) => (
                        <div key={index}>

                          <div className="PublicApplyForm__experience-item" key={item.id || 0}>

                            <div className="PublicApplyForm__experience-item-title-row">
                              <h2 className="PublicApplyForm__experience-item-title">
                                {item.degree} në {item.field_of_study}
                              </h2>
                              <Delete
                                sx={
                                  {fontSize: 20, cursor: 'pointer'}
                                }
                                onClick={() => {
                                  setEducations(educations.filter((education) => education !== item));
                                }}
                              />
                            </div>

                            <p>{item.school_name}</p>
                            <div className="PublicApplyForm__experience-item-date">
                              {`${new Date(item.start_date).toLocaleString("en-GB", {
                                month: "short",
                                year: "numeric",
                              })} - ${item.end_date === '' ? "Present" : new Date(item.end_date).toLocaleString("en-GB", {
                                month: "short",
                                year: "numeric",
                              })}`}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="PublicApplyForm__experience-item">
                        <div>{t("noEducationAddedYet")}</div>
                      </div>
                    )}
                  </p>

                </div>
              )}

              <div className="PublicApplyDetails__input-button-text-row">
                <p className="PublicApplyForm__button-front-text">
                  {t("experience")}
                </p>
                <MUIButton
                  variant="outlined"
                  startIcon={<Work/>}
                  color="inherit"
                  onClick={() => {
                    setOpenAddExperienceModal(true);
                  }}
                >
                  {t("addExperience")}
                </MUIButton>
              </div>

            </div>

            {experiences && (
              <div className="PublicApplyForm__personal-details-item">

                <p className="PublicApplyForm__personal-details-value">
                  {experiences && experiences.length > 0 ? (
                    experiences.map((item: any, index: number) => (
                      <div key={index}>

                        <div className="PublicApplyForm__experience-item" key={item.id || 0}>

                          <div className="PublicApplyForm__experience-item-title-row">
                            <h2 className="PublicApplyForm__experience-item-title">
                              {item.job_title} në {item.company_name}
                            </h2>
                            <Delete
                              sx={
                                {fontSize: 20, cursor: 'pointer'}
                              }
                              onClick={() => {
                                setExperiences(experiences.filter((experience) => experience !== item));
                              }}
                            />
                          </div>
                          <div className="PublicApplyForm__experience-item-date">
                            {`${new Date(item.start_date).toLocaleString("en-GB", {
                              month: "short",
                              year: "numeric",
                            })} - ${item.end_date === '' ? "Present" : new Date(item.end_date).toLocaleString("en-GB", {
                              month: "short",
                              year: "numeric",
                            })}`}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="PublicApplyForm__experience-item">
                      <div>{t("noExperienceAddedYet")}</div>
                    </div>
                  )}
                </p>
              </div>
            )}

            {base64ImageError && (
              <h5 className="PublicApplyDetails__uploader-error">
                {t("addProfilePicture")}
              </h5>
            )}
            <div className="PublicApplyDetails__footer">
              <div className="PublicApplyDetails__button">
                <Button
                  isLoading={isButtonLoading}
                  type="submit"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  label={t("apply")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicApplyForm;
